import React, { useEffect } from "react";
import { Router, useLocation, withRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { ToastProvider } from "react-toast-notifications";
import { history } from "./helpers";
import { login } from "./services/authService";
import Fulllayout from "./layouts/fulllayout";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loggedIn: false };
  }
  async componentDidMount() {
    const urlOrigin = window.location.origin;
    await login("guest", urlOrigin);
    this.setState({ loggedIn: true });
  }
  render() {
    return (
      this.state.loggedIn && (
        <Provider store={configureStore()}>
          <ToastProvider>
            <Router basename="/" history={history}>
              <ScrollToTop>
                <Fulllayout />
              </ScrollToTop>
            </Router>
          </ToastProvider>
        </Provider>
      )
    );
  }
}

export default App;
