import React, { Component } from "react";
import { Col, Carousel, CarouselItem, CarouselControl, Fade } from "reactstrap";
// import MetaTags from "react-meta-tags";
import BriefSearch from "../components/vehicle/briefSearch";
import { connect } from "react-redux";
// import { getDealership } from "../services/dealershipService";
import _ from "lodash";
import lineImg from "../images/brown_line.jpg";
import VehicleCarousel from "../components/vehicle/vehicleCarousel";
import GoogleMap from "../components/common/googleMap";
import styled, { keyframes, css } from "styled-components";
import {
  setShowingVehicles,
  setFilteredVehicles,
  setSelectedVehicle,
} from "../redux/vehicles/action";
import { Link, NavLink } from "react-router-dom";
import GoogleReview from "../components/common/googleReview";
import { colorObjToString, numberWithCommas } from "../utils/utils";
import { getFloorVehiclesWithFirstPicture } from "../services/vehicleService";
import PageMetaTags from "../components/common/pageMetaTags";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
  vehicles: state.vehiclesReducer.vehicles,
  showingVehicles: state.vehiclesReducer.showingVehicles,
});
const mapDispatchToProps = (dispatch) => ({
  setShowingVehicles: (vehicles) => dispatch(setShowingVehicles(vehicles)),
  setFilteredVehicles: (vehicles) => dispatch(setFilteredVehicles(vehicles)),
  setSelectedVehicle: (vehicle) => dispatch(setSelectedVehicle(vehicle)),
});

let counter = 0;

const imgCaption = keyframes`
from {
  opacity:0;
}
to {
  opacity:1;
}
`;
const animationImg = (props) =>
  css`
    ${imgCaption};
  `;
const StyledImg = styled.img`
  animation-name: ${animationImg};
  animation-duration: 3000ms;
  animation-fill-mode: forwards;
`;

const items = [
  {
    // altText: "Slide 1",
    // caption: (bannerTexts) => {
    caption: (websiteSettings) => {
      const { bannerTexts } = websiteSettings;
      const caption00 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
      }
      to {
        color: #fff;
      }
      `;
      const animation00 = (props) =>
        css`
          ${caption00};
        `;
      const Div00 = styled.div`
        top: ${websiteSettings.caption1.top};
        left: ${websiteSettings.caption1.left};
        animation-name: ${animation00};
      `;

      const caption01 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
      }
      to {
        color: #acd6f0;
      }
      `;
      const animation01 = (props) =>
        css`
          ${caption01};
        `;
      const Div01 = styled.div`
        top: calc(${websiteSettings.caption1.top} + 4vw);
        left: calc(${websiteSettings.caption1.left} + 5vw);
        animation-name: ${animation01};
      `;

      const caption02 = keyframes`
      from {
        border-top: none;
        color: rgba(0, 0, 0, 0);
        top: calc(${websiteSettings.caption1.top} + 10vw);
      }
      to {
        border-top: 2px solid #acd6f0;
        color: #fff;
        top: calc(${websiteSettings.caption1.top} + 7vw);
      }
      `;
      const animation02 = (props) =>
        css`
          ${caption02};
        `;
      const Div02 = styled.div`
        top: calc(${websiteSettings.caption1.top} + 4vw);
        left: calc(${websiteSettings.caption1.left} + 5vw);
        animation-name: ${animation02};
      `;

      const caption03 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
        left: calc(${websiteSettings.caption1.left} + 5vw);
      }
      to {
        color: #acd6f0;
        left: calc(${websiteSettings.caption1.left} + 7vw);
      }
      `;
      const animation03 = (props) =>
        css`
          ${caption03};
        `;
      const Div03 = styled.div`
        top: calc(${websiteSettings.caption1.top} + 9.5vw);
        left: calc(${websiteSettings.caption1.left} + 5vw);
        animation-name: ${animation03};
      `;

      const caption04 = keyframes`
      from {
        border-bottom: none;
        color: rgba(0, 0, 0, 0);
        top: calc(${websiteSettings.caption1.top} + 7vw);
      }
      to {
        border-bottom: 2px solid #acd6f0;
        color: #fff;
        top: calc(${websiteSettings.caption1.top} + 14vw);
      }
      `;
      const animation04 = (props) =>
        css`
          ${caption04};
        `;
      const Div04 = styled.div`
        top: calc(${websiteSettings.caption1.top} + 9.5vw);
        left: calc(${websiteSettings.caption1.left} + 5vw);
        animation-name: ${animation04};
      `;

      return (
        <div style={{ height: "0px" }}>
          <Div00 className="tp-caption mediumlarge_light_white_center domainate start">
            {bannerTexts[0][0]}
          </Div00>
          <Div01
            className="tp-caption internet"
            style={{ zIndex: 6, letterSpacing: "-3px" }}
          >
            {bannerTexts[0][1]}
          </Div01>
          <Div02 className="tp-caption attract" style={{ zIndex: 6 }}>
            {bannerTexts[0][2]}
          </Div02>
          <Div03 className="tp-caption more" style={{ zIndex: 6 }}>
            {bannerTexts[0][3]}
          </Div03>
          <Div04 className="tp-caption qualified" style={{ zIndex: 6 }}>
            {bannerTexts[0][4]}
          </Div04>
        </div>
      );
    },
    // src: img1,
  },
  {
    // altText: "Slide 2",
    caption: (websiteSettings) => {
      const { bannerTexts } = websiteSettings;
      const caption10 = keyframes`
      from {
        left: 100vw;
      }
      to {
        left: ${websiteSettings.caption2.left};
      }
      `;
      const animation10 = (props) =>
        css`
          ${caption10};
        `;
      const Div10 = styled.div`
        top: ${websiteSettings.caption2.top};
        color: ${colorObjToString(websiteSettings.slide2TextColor)};
        background-color: ${colorObjToString(
          websiteSettings.slide2TextBgColor
        )} !important;
        left: 100vw;
        animation-name: ${animation10};
      `;

      const caption11 = keyframes`
      from {
        left: 100vw;
      }
      to {
        left: ${websiteSettings.caption2.left};
      }
      `;
      const animation11 = (props) =>
        css`
          ${caption11};
        `;
      const Div11 = styled.span`
        top: calc(${websiteSettings.caption2.top} + 3.5vw);
        left: 100vw;
        animation-name: ${animation11};
      `;

      const caption12 = keyframes`
      from {
        left: 100vw;
      }
      to {
        left: calc(${websiteSettings.caption2.left} + 5.7vw);
      }
      `;
      const animation12 = (props) =>
        css`
          ${caption12};
        `;
      const Div12 = styled.span`
        top: calc(${websiteSettings.caption2.top} + 3.5vw);
        left: 100vw;
        color: ${colorObjToString(
          websiteSettings.slide2TextBgColor
        )} !important;
        animation-name: ${animation12};
      `;

      const caption13 = keyframes`
      from {
        left: 100vw;
      }
      to {
        left: calc(${websiteSettings.caption2.left} + 11.4vw);
      }
      `;
      const animation13 = (props) =>
        css`
          ${caption13};
        `;
      const Div13 = styled.span`
        top: calc(${websiteSettings.caption2.top} + 3.5vw);
        left: 100vw;
        color: ${colorObjToString(websiteSettings.slide2TextColor)} !important;
        animation-name: ${animation13};
      `;

      const caption14 = keyframes`
      from {
        left: 100vw;
      }
      to {
        left: calc(${websiteSettings.caption2.left} + 21vw);
      }
      `;
      const animation14 = (props) =>
        css`
          ${caption14};
        `;
      const Div14 = styled.span`
        top: calc(${websiteSettings.caption2.top} + 3vw);
        left: 100vw;
        color: ${colorObjToString(websiteSettings.slide2TextColor)} !important;
        animation-name: ${animation14};
      `;

      const caption15 = keyframes`
      from {
        left: 100vw;
      }
      to {
        left: ${websiteSettings.caption2.left};
      }
      `;
      const animation15 = (props) =>
        css`
          ${caption15};
        `;
      const Div15 = styled.img`
        top: calc(${websiteSettings.caption2.top} + 5.5vw);
        left: 100vw;
        color: ${colorObjToString(websiteSettings.slide2TextColor)} !important;
        animation-name: ${animation15};
      `;

      return (
        <div style={{ height: "0px" }}>
          <Div10 className="tp-caption mediumlarge_light_white_center invest">
            {bannerTexts[1][0]}
          </Div10>
          <Div11
            className="tp-caption move mediumlarge_light_white_center"
            style={{ zIndex: 6, letterSpacing: "-3px" }}
          >
            {bannerTexts[1][1]}
          </Div11>
          <Div12
            className="tp-caption light_brown mediumlarge_light_white_center sfl"
            style={{ zIndex: 6 }}
          >
            {bannerTexts[1][2]}
          </Div12>
          <Div13
            className="tp-caption dark_brown mediumlarge_light_white_center"
            style={{ zIndex: 6 }}
          >
            {bannerTexts[1][3]}
          </Div13>
          <Div14
            className="tp-caption faster mediumlarge_light_white_center"
            style={{ zIndex: 6 }}
          >
            {bannerTexts[1][4]}
          </Div14>
          {/* <span className="tp-caption brown_line" style={{ zIndex: 6 }}>
            <Div15 src={lineImg} alt="underline" />
          </span> */}
        </div>
      );
    },
    // src: img2,
  },
  {
    // altText: "Slide 3",
    caption: (websiteSettings) => {
      const { bannerTexts } = websiteSettings;
      const caption20 = keyframes`
      from {
        top: calc(${websiteSettings.caption3.top} + 12vw);
      }
      to {
        top: ${websiteSettings.caption3.top};
      }
      `;
      const animation20 = (props) =>
        css`
          ${caption20};
        `;
      const Div20 = styled.div`
        top: calc(${websiteSettings.caption3.top} + 12vw);
        left: ${websiteSettings.caption3.left};
        color: ${colorObjToString(websiteSettings.slide3TextColor)} !important;
        animation-name: ${animation20};
      `;

      const caption21 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
        top: 0px;
      }
      to {
        color: #fff;
        top: calc(${websiteSettings.caption3.top} + 2vw);
      }
      `;
      const animation21 = (props) =>
        css`
          ${caption21};
        `;
      const Div21 = styled.div`
        top: 0px;
        left: ${websiteSettings.caption3.left};
        animation-name: ${animation21};
      `;

      return (
        <div style={{ height: "0px" }}>
          <div>
            <Div20 className="tp-caption customin customout start green">
              {bannerTexts[2][0]}
            </Div20>
          </div>
          <div>
            <Div21 className="tp-caption big_white" style={{ zIndex: 6 }}>
              {bannerTexts[2][1]}
            </Div21>
          </div>
        </div>
      );
    },
    // src: img3,
  },
  {
    // altText: "Slide 4",
    caption: (websiteSettings) => {
      const { bannerTexts } = websiteSettings;
      const caption30 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
        left: calc(${websiteSettings.caption4.left} - 2vw);
      }
      to {
        color: #fff;
        left: ${websiteSettings.caption4.left};
      }
      `;
      const animation30 = (props) =>
        css`
          ${caption30};
        `;
      const Div30 = styled.span`
        top: ${websiteSettings.caption4.top};
        left: calc(${websiteSettings.caption4.left} - 2vw);
        animation-name: ${animation30};
      `;

      const caption31 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
        left: calc(${websiteSettings.caption4.left} + 28vw);
      }
      to {
        color: ${colorObjToString(websiteSettings.slide4TextColor)};
        left: calc(${websiteSettings.caption4.left} + 13vw);
      }
      `;
      const animation31 = (props) =>
        css`
          ${caption31};
        `;
      const Div31 = styled.span`
        top: ${websiteSettings.caption4.top};
        left: calc(${websiteSettings.caption4.left} + 28vw);
        animation-name: ${animation31};
      `;

      const caption32 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
        top: calc(${websiteSettings.caption4.top} + 6vw);
      }
      to {
        color: #fff;
        top: calc(${websiteSettings.caption4.top} + 14vw);
      }
      `;
      const animation32 = (props) =>
        css`
          ${caption32};
        `;
      const Div32 = styled.div`
        top: ${websiteSettings.caption4.top};
        left: ${websiteSettings.caption4.left};
        animation-name: ${animation32};
      `;

      const caption33 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
        top: calc(${websiteSettings.caption4.top} + 6vw);
      }
      to {
    color: #cbe2d2;
        top: calc(${websiteSettings.caption4.top} + 16.2vw);
      }
      `;
      const animation33 = (props) =>
        css`
          ${caption33};
        `;
      const Div33 = styled.div`
        top: ${websiteSettings.caption4.top};
        left: calc(${websiteSettings.caption4.left} + 30vw);
        animation-name: ${animation33};
      `;

      return (
        <div style={{ height: "0px" }}>
          <Div30 className="tp-caption customin customout start big_white2">
            {bannerTexts[3][0]}
          </Div30>
          <Div31 className="tp-caption thin_red" style={{ zIndex: 6 }}>
            {bannerTexts[3][1]}
          </Div31>
          <Div32
            className="tp-caption thin_white skewfromright"
            style={{ zIndex: 6 }}
          >
            {bannerTexts[3][2]}
          </Div32>
          <Div33
            className="tp-caption cursive skewfromright"
            style={{ zIndex: 6 }}
          >
            {bannerTexts[3][3]}
          </Div33>
        </div>
      );
    },
    // src: img4,
  },
  {
    // altText: "Slide 5",
    caption: (websiteSettings) => {
      const { bannerTexts } = websiteSettings;
      const caption40 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
      }
      to {
        color: #fff;
      }
          `;
      const animation40 = (props) =>
        css`
          ${caption40};
        `;
      const Div40 = styled.span`
        top: ${websiteSettings.caption5.top};
        left: ${websiteSettings.caption5.left};
        animation-name: ${animation40};
      `;

      const caption41 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
        font-size: 1vw;
      }
      to {
        color: ${colorObjToString(websiteSettings.slide5TextColor)};
        font-size: 3vw;
      }
      `;
      const animation41 = (props) =>
        css`
          ${caption41};
        `;
      const Div41 = styled.span`
        top: ${websiteSettings.caption5.top};
        left: calc(${websiteSettings.caption5.left} + 12vw);
        animation-name: ${animation41};
      `;

      const caption42 = keyframes`
      from {
        color: rgba(0, 0, 0, 0);
        font-size: 1vw;
      }
      to {
        color: #fff;
        font-size: 2.8vw;
      }
      `;
      const animation42 = (props) =>
        css`
          ${caption42};
        `;
      const Div42 = styled.div`
        top: calc(${websiteSettings.caption5.top} + 3vw);
        left: calc(${websiteSettings.caption5.left});
        animation-name: ${animation42};
      `;

      return (
        <div style={{ height: "0px" }}>
          <Div40 className="tp-caption mediumlarge_light_white_center2 domainate customin customout start">
            {bannerTexts[4][0]}
          </Div40>
          <Div41 className="tp-caption thin_dark_blue" style={{ zIndex: 6 }}>
            {bannerTexts[4][1]}
          </Div41>
          <Div42 className="tp-caption thinner_white sfl" style={{ zIndex: 6 }}>
            {bannerTexts[4][2]}
          </Div42>
        </div>
      );
    },
    // src: img5,
  },
];

const getDimensions = () => {
  let height = window.innerHeight / 2;
  let width = (height * 1024) / 768;
  if (window.innerWidth <= 1024) {
    width = window.innerWidth;
    height = (width * 768) / 1024;
  }
  const left =
    window.innerWidth <= 1024 ? 0 : window.innerWidth / 2 - width / 2;
  const makeTop = Math.round(
    height - width / (window.innerWidth <= 1024 ? 8 : 10)
  );
  const trimTop = Math.round(
    height - width / (window.innerWidth <= 1024 ? 14.5 : 20)
  );
  const priceTop = Math.round(
    height - width / (window.innerWidth <= 1024 ? 5.5 : 7)
  );
  return { height, width, left, makeTop, trimTop, priceTop };
};

const vehicleCaption = (vehicle) => {
  const caption20 = keyframes`
  from {
    top: 0px;
  }
  to {
    top: ${getDimensions().makeTop}px;
  }
  `;
  //   window.innerWidth >= 1024
  //     ? keyframes`
  // from {
  //   top: 1000px;
  // }
  // to {
  //   top: 640px;
  // }
  // `
  //     : keyframes`
  // from {
  //   top: 1000px;
  // }
  // to {
  //   top: calc(60vw);
  // }
  // `;
  const animation20 = (props) =>
    css`
      ${caption20};
    `;
  const Div20 = styled.div`
    left: ${getDimensions().left + 5}px;
    color: white !important;
    animation-name: ${animation20};
    width: ${getDimensions().width - 5}px;
    white-space: nowrap;
    overflow: hidden;
    font-size: ${Math.round(getDimensions().width / 25)}px;
  `;
  // window.innerWidth >= 1024
  //   ? styled.div`
  //       left: calc(50vw - 502px);
  //       color: white !important;
  //       animation-name: ${animation20};
  //     `
  //   : styled.div`
  //       left: 4px;
  //       color: white !important;
  //       animation-name: ${animation20};
  //     `;

  const caption21 = keyframes`
  from {
    color: rgba(0, 0, 0, 0);
    top: 1000px;
  }
  to {
    color: #fff;
    top: ${getDimensions().trimTop}px;
  }
  `;
  //   window.innerWidth >= 1024
  //     ? keyframes`
  // from {
  //   color: rgba(0, 0, 0, 0);
  //   top: 0px;
  // }
  // to {
  //   color: #fff;
  //   top: 710px;
  // }
  // `
  //     : keyframes`
  // from {
  //   color: rgba(0, 0, 0, 0);
  //   top: 0px;
  // }
  // to {
  //   color: #fff;
  //   top: calc(67vw);
  // }
  // `;
  const animation21 = (props) =>
    css`
      ${caption21};
    `;
  const Div21 = styled.div`
    left: ${getDimensions().left + 5}px;
    animation-name: ${animation21};
    font-size: ${Math.round(getDimensions().width / 40)}px;
  `;
  // window.innerWidth >= 1024
  //   ? styled.div`
  //       left: calc(50vw - 502px);
  //       animation-name: ${animation21};
  //     `
  //   : styled.div`
  //       left: 4px;
  //       animation-name: ${animation21};
  //     `;

  const caption22 = keyframes`
  from {
    color: rgba(0, 0, 0, 0);
    top: 1000px;
  }
  to {
    color: #fff;
    top: ${getDimensions().priceTop}px;
  }
  `;
  //   window.innerWidth >= 1024
  //     ? keyframes`
  // from {
  //   color: rgba(0, 0, 0, 0);
  //   top: 0px;
  // }
  // to {
  //   color: #fff;
  //   top: 690px;
  // }
  // `
  //     : keyframes`
  // from {
  //   color: rgba(0, 0, 0, 0);
  //   top: 0px;
  // }
  // to {
  //   color: #fff;
  //   top: calc(65vw);
  // }
  // `;
  const animation22 = (props) =>
    css`
      ${caption22};
    `;
  const Div22 = styled.div`
    right: ${getDimensions().left + 5}px;
    animation-name: ${animation22};
    font-size: ${Math.round(getDimensions().width / 25)}px;
  `;
  // window.innerWidth >= 1024
  //   ? styled.div`
  //       right: calc(50vw - 502px);
  //       animation-name: ${animation22};
  //     `
  //   : styled.div`
  //       right: 2vw;
  //       animation-name: ${animation22};
  //     `;

  return (
    <div style={{ height: "0px" }}>
      <div>
        <Div20
          className={
            "tp-caption customin customout start green2"
            // +
            // (window.innerWidth >= 1024 ? "2" : "3")
          }
        >
          {vehicle
            ? vehicle.productionYear +
              " " +
              vehicle.makeName +
              " " +
              vehicle.modelName +
              " " +
              (vehicle.trim || "")
            : ""}
        </Div20>
      </div>
      <div>
        <Div21
          className={
            "tp-caption normal_white"
            //  + (window.innerWidth >= 1024 ? "" : "2")
          }
          style={{ zIndex: 6 }}
        >
          {vehicle ? vehicle.marketingTrim || "" : ""}
        </Div21>
      </div>
      <div>
        <Div22
          className={
            "tp-caption customin customout start green2"
            // + (window.innerWidth >= 1024 ? "2" : "3")
          }
          style={{ zIndex: 6 }}
        >
          {vehicle && vehicle.askingPrice
            ? "$" + numberWithCommas(vehicle.askingPrice)
            : ""}
        </Div22>
      </div>
    </div>
  );
};

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

class FirstPage extends Component {
  state = { dealership: {}, activeIndex: 0, vehicles: [] };
  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = (slides) => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === slides.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = (slides) => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? slides.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  async componentDidMount() {
    const { data: vehicles } = await getFloorVehiclesWithFirstPicture();
    this.setState({ vehicles });
  }

  componentDidUpdate() {
    counter++;
  }

  render() {
    if (!this.props.dealership.address) return null;
    const { activeIndex } = this.state;
    const {
      name,
      address,
      city,
      province,
      country,
      postalCode,
      phoneNumber1,
      url,
      email,
      workingHours,
      websiteSettings,
      inventoryAsSlider,
      websitePages,
    } = this.props.dealership;
    const { height, width, left } = getDimensions();
    // console.log(
    //   "this.props.dealership.websiteSettings.sliderBgColor:",
    //   this.props.dealership.websiteSettings.sliderBgColor
    // );

    const slides = inventoryAsSlider
      ? _.orderBy(
          this.state.vehicles.filter((v) => v.picture),
          ["productionYear"],
          ["desc"]
        ).map((item, i) => {
          return (
            <CarouselItem
              onExiting={this.onExiting}
              onExited={this.onExited}
              key={"CarouselItem" + i + "_" + counter}
            >
              <div>
                {this.props.dealership.plainSliderBackground ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      height,
                      backgroundColor: colorObjToString(
                        this.props.dealership.websiteSettings.sliderBgColor
                      ),
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundImage: 'url("' + item.picture + '")',
                      position: "relative",
                      height,
                      filter: "blur(24px)",
                      WebkitFilter: "blur(24px)",
                    }}
                  ></div>
                )}
                <NavLink
                  to="/vehicleInfo"
                  activeStyle={{ cursor: "pointer" }}
                  onClick={() => {
                    this.props.setFilteredVehicles([...this.props.vehicles]);
                    this.props.setShowingVehicles([...this.props.vehicles]);
                    this.props.setSelectedVehicle(
                      this.props.vehicles.find((d) => d.id === item.id)
                    );
                  }}
                >
                  <StyledImg
                    src={item.picture}
                    // alt={item.altText}
                    // onClick={() => {
                    //   this.props.setFilteredVehicles({ ...item });
                    // }}
                    style={{
                      position: "absolute",
                      top: 0,
                      left,
                      width,
                      cursor: "pointer",
                    }}
                  />
                </NavLink>
                {websiteSettings ? vehicleCaption(item) : ""}
              </div>
            </CarouselItem>
          );
        })
      : items.map((item, i) => {
          return (
            <CarouselItem
              onExiting={this.onExiting}
              onExited={this.onExited}
              key={"CarouselItem" + i + "_" + counter}
            >
              <StyledImg
                src={
                  "./images/slide-show" +
                  websiteSettings.sliderImages[i] +
                  ".jpg"
                }
                alt={item.altText}
                style={{
                  width: "100%",
                }}
              />
              {websiteSettings ? item.caption(websiteSettings) : ""}
            </CarouselItem>
          );
        });
    // const pageIndex =
    //   websitePages &&
    //   websitePages.findIndex((s) => "/" + s === window.location.pathname);
    // const pageTitle = websiteSettings.metaTags[pageIndex][0] || name;
    // const pageDescription = websiteSettings.metaTags[pageIndex][1] || name;
    // const pageKeywords = websiteSettings.metaTags[pageIndex][2] || name;
    return (
      <div style={{ position: "relative" }}>
        <PageMetaTags />
        {/* <MetaTags>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="keywords" content={pageKeywords} />
        </MetaTags> */}
        <div className="clearfix"></div>
        <div className="banner-wrap">
          <Carousel
            activeIndex={activeIndex}
            next={() => {
              this.next(slides);
            }}
            previous={() => {
              this.previous(slides);
            }}
            keyboard={false}
            pause={false}
            ride="carousel"
            interval="8000"
            slide={false}
          >
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={() => {
                this.previous(slides);
              }}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={() => {
                this.next(slides);
              }}
            />
          </Carousel>
        </div>
        <div className="row d-flex justify-content-center message-wrap">
          {_.orderBy(_.uniqBy(this.props.vehicles, "makeName"), [
            "makeName",
          ]).map((v, i) => {
            return (
              <NavLink
                key={"inv" + i}
                to="/inventory"
                activeStyle={{ cursor: "pointer" }}
                title={v.makeName}
                onClick={() => {
                  this.props.setFilteredVehicles(
                    this.props.vehicles.filter((d) => d.makeName === v.makeName)
                  );
                  this.props.setShowingVehicles(
                    this.props.vehicles.filter((d) => d.makeName === v.makeName)
                  );
                }}
              >
                <img
                  style={{
                    height: "2vw",
                    marginLeft: "1vw",
                  }}
                  src={
                    "/images/carLogos/" + v.makeName.toLowerCase() + "-logo.png"
                  }
                  alt={v.makeName}
                />
              </NavLink>
            );
          })}
        </div>
        <section className="content">
          <div className="container">
            <div className="inner-page homepage margin-bottom-none">
              <section className="welcome-wrap padding-top-10 sm-horizontal-15">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 welcome padding-left-none padding-bottom-20 scroll_effect fadeInUp">
                    <h4 className="margin-bottom-25 margin-top-none">
                      <strong>WELCOME</strong> TO OUR WEBSITE
                    </h4>
                    <p className="text-justify">
                      {this.props.dealership.websiteSettings.welcomeMessage}
                    </p>
                  </div>
                  {/* ------------------------------------------------------------------------------------------------------------------- */}
                  {/* ------------------------------------------------------------------------------------------------------------------- */}
                  {/* ------------------------------------------------------------------------------------------------------------------- */}
                  <div
                    className="col-lg-6 col-md-12 col-sm-12 col-xs-12 padding-bottom-20 scroll_effect fadeInUp"
                    data-wow-delay=".2s"
                    style={{ zIndex: "100" }}
                  >
                    <h4 className="margin-bottom-25 margin-top-none">
                      <strong>SEARCH</strong> OUR INVENTORY
                    </h4>
                    {/* <div id="briefSearch_container"></div> */}
                    <BriefSearch />
                  </div>
                  {/* ------------------------------------------------------------------------------------------------------------------- */}
                  {/* ------------------------------------------------------------------------------------------------------------------- */}
                  {/* ------------------------------------------------------------------------------------------------------------------- */}
                </div>
                <div className="clearfix"></div>
                {/* <div id="recentVehicles_container"></div> */}
                <VehicleCarousel recent={true} />
                <div className="clearfix"></div>
              </section>

              <div
                className="fullwidth_element"
                style={{
                  // height: "390px",
                  width: "100vw",
                  marginLeft: "calc(-50vw + 50%)",
                }}
              >
                <GoogleReview />
              </div>

              <div
                className="fullwidth_element"
                // data-zoom="12"
                style={{
                  height: "390px",
                  width: "100vw",
                  marginLeft: "calc(-50vw + 50%)",
                }}
                // data-scroll="false"
                // data-style='[{"featureType":"landscape","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"stylers":[{"hue":"#F0F0F0"},{"saturation":-100},{"gamma":2.15},{"lightness":12}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"lightness":24}]},{"featureType":"road","elementType":"geometry","stylers":[{"lightness":57}]}]'
              >
                <GoogleMap
                  dealershipName={
                    address
                      ? address +
                        " " +
                        city +
                        " " +
                        province +
                        " " +
                        postalCode +
                        " " +
                        name
                      : ""
                  }
                />
              </div>
              {/* </div> */}
              <div className="car-rate-block row clearfix margin-top-30 padding-bottom-40 pt-4">
                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-none padding-left-none scroll_effect bounceInLeft">
                  <div className="small-block clearfix">
                    <h4 className="margin-bottom-25 margin-top-none">
                      Financing.
                    </h4>
                    <Link to="creditApplication">
                      <span className="align-center">
                        <i className="fa fa-tag fa-7x"></i>
                      </span>
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-2 col-md-6 col-sm-6 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-none scroll_effect bounceInLeft"
                  data-wow-delay=".2s"
                >
                  <div className="small-block clearfix">
                    <h4 className="margin-bottom-25 margin-top-none">
                      Warranty.
                    </h4>
                    <Link to="faq">
                      {/* <a href="#"> */}
                      <span className="align-center">
                        <i className="fa fa-cogs fa-7x"></i>
                      </span>
                      {/* </a> */}
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 padding-left-none padding-right-none hours_operation">
                  <div className="small-block clearfix">
                    <h4 className="margin-bottom-25 margin-top-none">
                      What are our Hours of Operation?
                    </h4>
                    <div className="row">
                      <Col xs="3"></Col>
                      <div
                        className="text-center col-lg-6 col-md-6 col-sm-6 col-xs-6 xs-margin-bottom-40 sm-margin-bottom-40 md-margin-bottom-none scroll_effect bounceInUp"
                        data-wow-delay=".4s"
                      >
                        {/* <div
                        className="col-lg-6 col-md-12 col-sm-12 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-40 md-margin-bottom-none scroll_effect bounceInUp"
                        data-wow-delay=".4s"
                      > */}
                        <table className="table table-bordered no-border font-13px margin-bottom-none">
                          {/* <thead>
                            <tr>
                              <td>
                                <strong>Sales Department</strong>
                              </td>
                            </tr>
                          </thead> */}
                          <tbody>
                            {weekDays.map((w, i) =>
                              workingHours ? (
                                <tr key={w}>
                                  <td>{w + ":"}</td>
                                  <td>
                                    {(workingHours[i][0] || "") +
                                      " - " +
                                      (workingHours[i][1] || "")}
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <Col xs="3"></Col>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-2 col-md-6 col-sm-6 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-none scroll_effect bounceInRight"
                  data-wow-delay=".2s"
                >
                  <div className="small-block clearfix">
                    <h4 className="margin-bottom-25 margin-top-none">
                      About Us.
                    </h4>
                    <Link to="about">
                      <span className="align-center">
                        <i className="fa fa-users fa-7x"></i>
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-none padding-right-none scroll_effect bounceInRight">
                  <div className="small-block clearfix">
                    <h4 className="margin-bottom-25 margin-top-none">
                      Find Us.
                    </h4>
                    <Link to="contact">
                      <span className="align-center">
                        <i className="fa fa-map-marker fa-7x"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* </section> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// export default FirstPage;
export default connect(mapStateToProps, mapDispatchToProps)(FirstPage);
