import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Carousel from "../components/common/carousel2";
import RequestInfo from "../components/customer/requestInfo";
import ShareVehicle from "../components/customer/shareVehicle";
import VehicleCarousel from "../components/vehicle/vehicleCarousel";
import { numberWithCommas } from "../utils/utils";
import { Link, useLocation } from "react-router-dom";
import {
  setSelectedVehicle,
  setFilteredVehicles,
  setShowingVehicles,
  setVehicles,
} from "../redux/vehicles/action";
import _ from "lodash";
import Calculator from "../components/common/financingCalculator";
import { Row, Col } from "reactstrap";
import { getVehicles } from "../services/vehicleService";
import RentVehicle from "../components/customer/rentVehicle";
import PageMetaTags from "../components/common/pageMetaTags";
import soldStamp from "../images/sold.png";
import comingSoonStamp from "../images/comingSoon.png";
import comingSoon from "../images/comingSoon.jpg";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
  vehicles: state.vehiclesReducer.vehicles,
  filteredVehicles: state.vehiclesReducer.filteredVehicles,
  showingVehicles: state.vehiclesReducer.showingVehicles,
  selectedVehicle: state.vehiclesReducer.selectedVehicle,
});

const mapDispatchToProps = (dispatch) => ({
  setVehicles: (vehicle) => dispatch(setVehicles(vehicle)),
  setSelectedVehicle: (vehicle) => dispatch(setSelectedVehicle(vehicle)),
  setFilteredVehicles: (vehicle) => dispatch(setFilteredVehicles(vehicle)),
  setShowingVehicles: (vehicle) => dispatch(setShowingVehicles(vehicle)),
});

function VehicleInfo(props) {
  const [vehicleIndex, setVehicleIndex] = useState(-1);
  const [requestInfo, setRequestInfo] = useState(false);
  const [shareVehicle, setShareVehicle] = useState(false);
  const [rentModal, setRentModal] = useState(false);
  useEffect(() => {
    const vIndex = props.showingVehicles.findIndex(
      (v) => v.id == props.selectedVehicle.id
    );
    setVehicleIndex(vIndex);
  }, [props.selectedVehicle]);

  let location = useLocation();
  const passedVin = location.search;
  useEffect(() => {
    if (
      passedVin
      // &&
      // props.vehicles.length > 0 &&
      // (_.isEmpty(props.selectedVehicle) ||
      //   props.selectedVehicle.vin.toUpperCase() !==
      //     passedVin.substring(1, 18).toUpperCase())
    ) {
      getVehicles({}).then(({ data }) => {
        props.setVehicles(data);
        const i = data.findIndex(
          (v) =>
            v.vin.toUpperCase() === passedVin.substring(1, 18).toUpperCase()
        );
        if (i !== -1) {
          props.setFilteredVehicles([...data]);
          props.setShowingVehicles([...data]);
          props.setSelectedVehicle(data[i]);
        }
      });
    }
  }, []);

  const toAdDescription = (adSettings) => {
    let postingBody = [];
    if (adSettings)
      for (const section of Object.keys(adSettings))
        for (const sectionObject of adSettings[section]) {
          if (sectionObject.checked)
            postingBody.push(
              <div>
                {sectionObject.items.map((item, i) =>
                  item.checked ? <div key={"itm" + i}>{item.text}</div> : ""
                )}
              </div>
            );
        }
    return postingBody;
  };

  const toggleModal = () => {
    setRequestInfo(!requestInfo);
  };

  const toggleModal2 = () => {
    setShareVehicle(!shareVehicle);
  };

  const toggleModal3 = () => {
    setRentModal(!rentModal);
  };

  if (props.showingVehicles.length === 0 || vehicleIndex < 0) return null;
  const {
    askingPrice,
    bodyType,
    color,
    engine,
    id,
    makeName,
    mileage,
    modelName,
    productionYear,
    stockNumber,
    vehiclePictures,
    vin,
    drivetrainType,
    transmissionType,
    adSettings,
    doors,
    fuelType,
    trim,
    marketingTrim,
    websiteBadge,
    badgeColor,
    condition,
    adBodyText,
    floor,
    sold,
  } = props.showingVehicles[vehicleIndex];
  // props.selectedVehicle;
  return (
    <div>
      <PageMetaTags />
      <Modal
        style={{
          position: "relative",
          margin: "0 auto",
          top: "25%",
        }}
        isOpen={requestInfo}
        toggle={toggleModal}
        size="md"
        // onClose={() => closeChat()}
        unmountOnClose={true}
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>Request More Info</ModalHeader>
        <ModalBody>
          <RequestInfo
            toggleModal={toggleModal}
            vehicleId={props.selectedVehicle.id}
          />
        </ModalBody>
      </Modal>

      <Modal
        style={{
          position: "relative",
          margin: "0 auto",
          top: "25%",
        }}
        isOpen={shareVehicle}
        toggle={toggleModal2}
        size="md"
        // onClose={() => closeChat()}
        unmountOnClose={true}
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal2}>Share Vehicle</ModalHeader>
        <ModalBody>
          <ShareVehicle
            toggleModal={toggleModal2}
            vehicleId={props.selectedVehicle.id}
          />
        </ModalBody>
      </Modal>

      <Modal
        style={{
          position: "relative",
          margin: "0 auto",
          top: "25%",
        }}
        isOpen={rentModal}
        toggle={toggleModal3}
        size="lg"
        // onClose={() => closeChat()}
        unmountOnClose={true}
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal3}>Rent Vehicle</ModalHeader>
        <ModalBody>
          <RentVehicle
            toggleModal={toggleModal3}
            vehicleId={props.selectedVehicle.id}
            rentPerDay={props.selectedVehicle.rentPerDay}
          />
        </ModalBody>
      </Modal>

      <div className="clearfix"></div>
      <section id="secondary-banner" className="dynamic-image-8">
        {/* <!--for other images just change the class name of this section block like, className="dynamic-image-2" and add css for the changed class--> */}
        <div className="container">
          <h2>Inventory Listing</h2>
          <h4>Browse and find best available cars for your need!</h4>
        </div>
      </section>
      <div className="message-shadow"></div>
      <div className="clearfix"></div>
      <section className="content">
        <div className="container">
          <div className="inner-page inventory-listing">
            <div className="inventory-heading margin-bottom-10 clearfix">
              <Row>
                <Col xs="7" sm="8" md="9" lg="10">
                  <h2>
                    {productionYear +
                      " " +
                      makeName +
                      " " +
                      modelName +
                      " " +
                      (trim || "") +
                      " " +
                      (marketingTrim || "")}
                  </h2>
                </Col>
                <Col xs="5" sm="4" md="3" lg="2" className="text-right">
                  <h2>
                    {sold
                      ? "SOLD"
                      : !floor
                      ? "COMING SOON!"
                      : "$" + (numberWithCommas(askingPrice) || "")}
                  </h2>
                  <em>Plus Taxes &amp; Doc Fee</em>
                </Col>
              </Row>
            </div>
            {window.innerWidth > 767 && (
              <div className="content-nav margin-bottom-30">
                <div className="row">
                  <div className="col-lg-12">
                    <ul style={{ cursor: "pointer" }}>
                      <li className="prev1 gradient_button">
                        {/* <a href="#">Prev Vehicle</a> */}
                        <span
                          className="ml-3"
                          onClick={() => {
                            const i = vehicleIndex;
                            if (i && i > 0) {
                              // setVehicleIndex(i - 1);
                              props.setSelectedVehicle(
                                props.showingVehicles[i - 1]
                              );
                            }
                          }}
                        >
                          Previous Vehicle
                        </span>
                      </li>
                      <li className="request gradient_button">
                        <span
                          className="ml-3 fancybox_div"
                          onClick={
                            toggleModal
                            //   () => {
                            //   this.setState({ requestInfo: true });
                            // }
                          }
                        >
                          Request More Info
                        </span>
                      </li>
                      <li className="schedule gradient_button">
                        <Link to="contact" className="fancybox_div">
                          Schedule Test Drive
                        </Link>
                      </li>
                      {/* <li className="offer gradient_button">
                        <Link to="contact" className="fancybox_div">
                          Make an Offer
                        </Link>
                      </li> */}
                      {/* <li className="trade gradient_button">
                        <Link to="contact" className="fancybox_div">
                          Trade-In Appraisal
                        </Link>
                      </li> */}
                      <li className="pdf gradient_button">
                        <span
                          className="ml-3 fancybox_div"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              "https://www." +
                                props.dealership.url +
                                "/vehicleInfo?" +
                                vin
                            );
                          }}
                        >
                          Url
                        </span>
                      </li>
                      {/* <li className="print gradient_button">
                        <a className="print_page">Print This Vehicle</a>
                      </li> */}
                      <li className="email gradient_button">
                        <span
                          className="ml-3 fancybox_div"
                          onClick={toggleModal2}
                        >
                          Email to a Friend
                        </span>
                      </li>
                      <li className="next1 gradient_button">
                        {/* <a href="#">Next Vehicle</a> */}
                        <span
                          className="mr-3"
                          onClick={() => {
                            const i = vehicleIndex;
                            if (
                              props.showingVehicles &&
                              i !== null &&
                              i < props.showingVehicles.length - 1
                            ) {
                              // this.setState({ vehicleIndex: i + 1 });
                              // setVehicleIndex(i + 1);
                              props.setSelectedVehicle(
                                props.showingVehicles[i + 1]
                              );
                            }
                          }}
                        >
                          Next Vehicle
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}{" "}
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 left-content">
                <div className="listing-slider">
                  {websiteBadge && (
                    <div
                      className={
                        "angled_badge " +
                        (badgeColor ? badgeColor.toLowerCase() : "")
                      }
                    >
                      <span>{websiteBadge}</span>
                    </div>
                  )}
                  {sold && (
                    <img
                      style={{
                        position: "absolute",
                        right: 30,
                        top: 10,
                        zIndex: 999,
                        width: "40%",
                      }}
                      src={soldStamp}
                    />
                  )}
                  {!sold && !floor && (
                    <img
                      style={{
                        position: "absolute",
                        right: 30,
                        top: 10,
                        zIndex: 999,
                        width: "50%",
                      }}
                      src={comingSoonStamp}
                    />
                  )}
                  <section className="slider home-banner">
                    <div className="flexslider" id="home-slider-canvas">
                      {vehiclePictures.length > 0 ? (
                        <Carousel
                          items={vehiclePictures.map((vp, i) => {
                            return (
                              <div key={"vpc" + i}>
                                <img src={vp} alt="" />
                              </div>
                            );
                          })}
                        />
                      ) : (
                        <img src={comingSoon} />
                      )}
                    </div>
                  </section>
                  {/* <section className="slider home-banner">
                  <div className="flexslider" id="home-slider-canvas">
                    <div className="slides">
                      <Carousel
                        items={vehiclePictures.map((vp) => {
                          return <img src={vp} alt="" />;
                        })}
                      />
                    </div>
                  </div>
                </section>
                <section className="home-slider-thumbs">
                  <div className="flexslider" id="home-slider-thumbs">
                    <ul className="slides">
                      {vehiclePictures.map((vp) => {
                        return (
                          <li data-thumb={vp}>
                            <a href="#">
                              <img src={vp} alt="" />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </section> */}
                </div>
                <div className="clearfix"></div>
                <div className="bs-example bs-example-tabs example-tabs margin-top-50">
                  <ul id="myTab" className="nav nav-tabs">
                    <li>
                      <a href="#vehicle" data-toggle="tab" className="active">
                        Vehicle Overview
                      </a>
                    </li>
                    <li>
                      {/* <a href="#features" data-toggle="tab"> */}
                      <a href="#" data-toggle="tab">
                        Features &amp; Options
                      </a>
                    </li>
                    <li>
                      {/* <a href="#technical" data-toggle="tab"> */}
                      <a href="#" data-toggle="tab">
                        Technical Specifications
                      </a>
                    </li>
                    <li>
                      {/* <a href="#location" data-toggle="tab"> */}

                      <a href="#" data-toggle="tab">
                        <span
                          className="fancybox_div"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              "https://www." +
                                props.dealership.url +
                                "/vehicleInfo?" +
                                vin
                            );
                          }}
                        >
                          Vehicle Url
                        </span>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" data-toggle="tab">
                        Other Comments
                      </a>
                    </li> */}
                  </ul>
                  <div
                    id="myTabContent"
                    className="tab-content margin-top-15 margin-bottom-20"
                  >
                    <div className="tab-pane in active" id="vehicle">
                      {adBodyText
                        ? adBodyText
                            .split("\n")
                            .map((d, i) => <div key={"adBody" + i}>{d}</div>)
                        : toAdDescription(adSettings)}
                    </div>
                    <div className="tab-pane" id="features">
                      <ul className="fa-ul">
                        <li>
                          <i className="fa-li fa fa-check"></i> Adaptive Cruise
                          Control
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Airbags
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Air Conditioning
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Alarm System
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Anti-theft
                          Protection
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Audio Interface
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Automatic
                          Climate Control
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Automatic
                          Headlights
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Auto Start/Stop
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Bi-Xenon
                          Headlights
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Bluetooth®
                          Handset
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> BOSE® Surround
                          Sound
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Burmester®
                          Surround Sound
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> CD/DVD
                          Autochanger
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> CDR Audio
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Cruise Control
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Direct Fuel
                          Injection
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Electric Parking
                          Brake
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Floor Mats
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Garage Door
                          Opener
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Leather Package
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Locking Rear
                          Differential
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Luggage
                          Compartments
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Manual
                          Transmission
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Navigation
                          Module
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Online Services
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> ParkAssist
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Porsche
                          Communication
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Power Steering
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Reversing Camera
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Roll-over
                          Protection
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Seat Heating
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Seat Ventilation
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Sound Package
                          Plus
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Sport Chrono
                          Package
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Steering Wheel
                          Heating
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Tire Pressure
                          Monitoring
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Universal Audio
                          Interface
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Voice Control
                          System
                        </li>
                        <li>
                          <i className="fa-li fa fa-check"></i> Wind Deflector
                        </li>
                      </ul>
                    </div>
                    <div className="tab-pane" id="technical">
                      <table className="technical">
                        <thead>
                          <tr>
                            <th>Engine</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Layout / number of cylinders</td>
                            <td>6</td>
                          </tr>
                          <tr>
                            <td>Displacement</td>
                            <td>3.4 l</td>
                          </tr>
                          <tr>
                            <td>Engine Layout</td>
                            <td>Mid-engine</td>
                          </tr>
                          <tr>
                            <td>Horespower</td>
                            <td>315 hp</td>
                          </tr>
                          <tr>
                            <td>@ rpm</td>
                            <td>6,700 rpm</td>
                          </tr>
                          <tr>
                            <td>Torque</td>
                            <td>266 lb.-ft.</td>
                          </tr>
                          <tr>
                            <td>Compression ratio</td>
                            <td>12.5 : 1</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="technical">
                        <thead>
                          <tr>
                            <th>Performance</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Top Track Speed</td>
                            <td>173 mph</td>
                          </tr>
                          <tr>
                            <td>0 - 60 mph</td>
                            <td>4.8 s</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="technical">
                        <thead>
                          <tr>
                            <th>Transmission</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Manual Gearbox</td>
                            <td>
                              6-speed with dual-mass flywheel and self-adjusting
                              clutch
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="technical">
                        <thead>
                          <tr>
                            <th>Fuel consumption</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>City (estimate)</td>
                            <td>20</td>
                          </tr>
                          <tr>
                            <td>Highway (estimate)</td>
                            <td>28</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="technical">
                        <thead>
                          <tr>
                            <th>Body</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Length</td>
                            <td>172.2 in.</td>
                          </tr>
                          <tr>
                            <td>Width</td>
                            <td>70.9 in.</td>
                          </tr>
                          <tr>
                            <td>Height</td>
                            <td>50.4 in.</td>
                          </tr>
                          <tr>
                            <td>Wheelbase</td>
                            <td>97.4 in.</td>
                          </tr>
                          <tr>
                            <td>Maximum payload</td>
                            <td>739 lbs</td>
                          </tr>
                          <tr>
                            <td>Curb weight</td>
                            <td>2910 lbs</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="technical">
                        <thead>
                          <tr>
                            <th>Capacities</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Luggage compartment volume</td>
                            <td>5.3 cu. ft. (front) / 4.6 cu. ft. (rear)</td>
                          </tr>
                          <tr>
                            <td>Fuel Tank Capacity</td>
                            <td>16.9 gal.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="tab-pane" id="location">
                      <div
                        id="google-map-listing"
                        className="contact"
                        data-longitude="-79.38"
                        data-latitude="43.65"
                        data-zoom="11"
                        style={{ height: "350px" }}
                        data-parallax="false"
                      ></div>
                    </div>
                    <div className="tab-pane fade" id="comments">
                      <p>
                        Vestibulum sit amet ligula eget nibh cursus bibendum et
                        id eros. Nam congue, dui quis consectetur blandit, neque
                        neque mattis diam, vitae egestas urna lectus eu turpis.
                        In vitae commodo sem. Etiam vehicula sed ligula
                        malesuada cursus. Cras augue elit, tempus at dignissim
                        sed, egestas eget leo. Vestibulum ante ipsum primis in
                        faucibus orci luctus et ultrices posuere cubilia Curae;
                        Nam mollis luctus nibh et bibendum. Morbi congue lectus
                        nec congue congue. Nulla molestie feugiat quam ac
                        sollicitudin. Nulla sed congue lectus. Donec blandit
                        elit sit amet aliquet laoreet.
                      </p>
                      <p>
                        <img src="images/engine.png" alt="engine" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 right-content">
                {props.selectedVehicle.rentable && (
                  <button
                    style={{
                      width: "100%",
                      display: "block",
                      fontWeight: "bold",
                    }}
                    onClick={() => setRentModal(true)}
                  >
                    {"Rent This Vehicle For $" +
                      props.selectedVehicle.rentPerDay +
                      " Per Day"}
                  </button>
                )}
                <div className="side-content">
                  <div className="car-info margin-bottom-20">
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Body Style:</td>
                            <td>{bodyType || ""}</td>
                          </tr>
                          <tr>
                            <td>ENGINE:</td>
                            <td>{engine ? engine + " L" : ""}</td>
                          </tr>
                          <tr>
                            <td>TRANSMISSION:</td>
                            <td>{transmissionType || ""}</td>
                          </tr>
                          <tr>
                            <td>DRIVETRAIN:</td>
                            <td>{drivetrainType || ""}</td>
                          </tr>
                          <tr>
                            <td>EXTERIOR:</td>
                            <td>{color || ""}</td>
                          </tr>
                          {/* <tr>
                          <td>INTERIOR:</td>
                          <td>Agate Grey</td>
                        </tr> */}
                          <tr>
                            <td>Kilometers:</td>
                            <td>{numberWithCommas(mileage)}</td>
                          </tr>
                          <tr>
                            <td>DOORS:</td>
                            <td>{doors || ""}</td>
                          </tr>
                          {/* <tr>
                          <td>PASSENGERS:</td>
                          <td>2</td>
                        </tr> */}
                          <tr>
                            <td>STOCK #:</td>
                            <td>{stockNumber}</td>
                          </tr>
                          <tr>
                            <td>VIN #:</td>
                            <td>{vin}</td>
                          </tr>
                          {/* <tr>
                          <td>FUEL MILEAGE:</td>
                          <td>20 City / 28 Hwy</td>
                        </tr> */}
                          <tr>
                            <td>FUEL TYPE:</td>
                            <td>{fuelType || ""}</td>
                          </tr>
                          <tr>
                            <td>CONDITION:</td>
                            <td>{condition || ""}</td>
                          </tr>
                          {/* <tr>
                          <td>OWNERS:</td>
                          <td>N/A</td>
                        </tr> */}
                          {/* <tr>
                            <td>
                              {"https://www." +
                                props.dealership.url +
                                "/vehicleInfo?" +
                                vin}
                            </td>
                          </tr> */}
                          {/* <tr>
                            <td>WARRANTY:</td>
                            <td>3 Months Limited</td>
                          </tr> */}
                        </tbody>
                      </table>
                      <div
                        style={{
                          textAlign: "left",
                          marginLeft: "8px",
                          marginTop: "15px",
                          // display: "inline-block",
                          // overflowWrap: "break-word",
                          // wordBreak: "break-word",
                          // hyphens: "auto",
                        }}
                      >
                        {"https://" +
                          props.dealership.url +
                          "/vehicleInfo?" +
                          vin}
                      </div>
                    </div>
                  </div>
                  <button
                    style={{ display: "block", width: "100%" }}
                    onClick={toggleModal}
                  >
                    Request More Info
                  </button>
                  <div className="clearfix"></div>
                  <div className="financing_calculator margin-top-40">
                    <h3>FINANCING CALCULATOR</h3>
                    <Calculator />
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <VehicleCarousel recent={false} />
            {/* <div className="recent-vehicles-wrap">
              <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12 recent-vehicles xs-padding-bottom-20">
                  <h5 className="margin-top-none">Similar Vehicles</h5>
                  <p>
                    Browse through the vast selection of vehicles that have
                    recently been added to our inventory.
                  </p>
                  <div className="arrow3 clearfix" id="slideControls3">
                    <span className="prev-btn"></span>
                    <span className="next-btn"></span>
                  </div>
                </div>
                <div className="col-lg-10 col-md-8 col-sm-8">
                  <div className="carasouel-slider3">
                    {(() => {
                      const allVehicles = props.filteredVehicles;
                      const filteredVehicles = allVehicles.filter(
                        (v) => v.bodyType === bodyType
                      );
                      const result = filteredVehicles.map((vehicle, ind) => {
                        return (
                          <div className="slide" key={ind}>
                            <div className="angled_badge blue">
                              <span>{websiteBadge}</span>
                            </div>
                            <div className="car-block">
                              <div className="img-flex">
                                <div
                                  onClick={() => {
                                    const i = filteredVehicles.findIndex(
                                      (v) => v.id === vehicle.id
                                    );
                                    this.setState({
                                      vehicles: [...filteredVehicles],
                                      vehicleIndex: i,
                                    });
                                  }}
                                >
                                  <span className="align-center">
                                    <i className="fa fa-3x fa-plus-square-o"></i>
                                  </span>
                                </div>
                                <img
                                  src={
                                    vehicle.vehiclePictures.length > 0
                                      ? vehicle.vehiclePictures[0]
                                      : ""
                                  }
                                  alt=""
                                  className="img-responsive"
                                />
                              </div>
                              <div className="car-block-bottom">
                                <h6>
                                  <strong>
                                    {vehicle.productionYear +
                                      " " +
                                      vehicle.makeName +
                                      " " +
                                      vehicle.modelName}
                                  </strong>
                                </h6>
                                <h6>{vehicle.mileage + " kilometers"}</h6>
                                <h5>{"$" + vehicle.askingPrice}</h5>
                              </div>
                            </div>
                          </div>
                        );
                      });
                      return result;
                    })()}
                  </div>
                </div>
              </div>
            </div> */}
            <div className="clearfix"></div>
          </div>
        </div>
      </section>
    </div>
  );
  // }
}

// export default VehicleInfo;
export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfo);
